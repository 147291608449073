<template>
    <div class="picker-wrapper">

        <div class="grid gap-15">
            <InputDropDownList name="Type" selectAttribute="type" showAttribute="name" v-model="item.type" :options="[{ type: 'point', name: 'City' }, { type: 'areas', name: 'States' }]"/>
            
            <div v-if="item.type === 'areas'" class="grid gap-5">
                <InputDropDownCheckbox name="States" placeholder="Select states" selectAttribute="abbreviation" showAttribute="abbreviation" v-model="item.areas" :options="US_STATES"/>
                <div><a href="" @click.prevent="selectStates">Interactive selection</a>&nbsp;|&nbsp;<a href="" @click.prevent="item.areas = []">Clear</a></div>
            </div>
            <div v-else>
                <CitySearch v-model="item.point"/>
            </div>

            
            <Input v-model="deadHeadVal" name="DH miles" placeholder="DH miles" v-if="item.type === 'point'"/>
        </div>

    </div>
</template>

<script>
import CitySearch from '../../../../components/Dispatch/Loads/CitySearch.vue';
import StatesPickerVue from './StatesPicker.vue';


    export default {
        components: {
            CitySearch,
            StatesPickerVue
        },
        props: ['item', 'deadhead', 'modal'],
        data() {
            return {
                deadHeadVal: this.deadhead || 150
            }
        },
        watch: {
            deadHeadVal() {
                this.modal.setProp('deadhead', this.deadHeadVal);
            },
            "item.type"() {
                if(this.item.type === 'point') delete this.item.areas;
                if(this.item.type === 'areas') delete this.item.point;
            }
        },
        methods: {
            selectStates() {
                let modal = this.$ShowModal({
                    title: 'Select States',
                    component: StatesPickerVue,
                    width: '762px',
                    props: {
                        states: this.item.areas || []
                    }
                });

                modal.onConfirm((props) => {
                    this.item.areas = props.states || []
                    modal.close();
                });
            }
        },
        mounted() {
            if(!this.item.type) {
                this.item.type = 'point';
            }
        }
    }
</script>

<style lang="scss" scoped>
.picker-wrapper {
    padding: 20px;
}
</style>