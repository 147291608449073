<template>
  <div class="wrapper">
    <ActionBar title="Load board">
      <div
        style="display: flex; gap: 10px; align-items: center; flex-wrap: wrap"
      >
        <Button text="Add Post" size="small" @click="savePost({})" />
        <Button
          text="Credit Check"
          size="small"
          @click="ui_open_credit_check(null, null)"
          theme="white"
          :disabled="true"
        />
        <Button
          :text="`${mobileVersion ? 'Desktop' : 'Mobile'} Version`"
          icon="fa-solid fa-repeat"
          size="small"
          @click="mobileVersion = !mobileVersion"
          theme="white"
        />
        <Button
          text="Update Truckstop Token"
          size="small"
          @click="updateTruckstopToken"
          theme="white"
        />
      </div>
    </ActionBar>

    <div class="posts">
      <PostItem
        @select="selectPost(post, key)"
        @remove="removePost(post, key)"
        @edit="savePost(post)"
        class="post"
        :class="{ active: selected === key }"
        v-for="(post, key) of posts"
        :key="post._id"
        :post="post"
        :counter="loadsCounter[post._id]"
      />
    </div>

    <div class="table-holder" v-if="!mobileVersion">
      <div class="LoadBoard-load header">
        <div
          :key="key"
          v-for="(item, key) of table.head"
          class="text-overflow header-col"
          @click="selectSort(item)"
        >
          <div>{{ item.name }}</div>
          <div v-if="item.sort && selectedSort === item.sortKey">
            <span v-if="orderAsc"><i class="fa-solid fa-arrow-down"></i></span>
            <span v-else><i class="fa-solid fa-arrow-up"></i></span>
          </div>
        </div>
      </div>
      <div v-if="loads">
        <LoadItem
          class="load"
          :isAvailable="
            availableLoadsByCurrentPost
              ? availableLoadsByCurrentPost.includes(load.id)
              : true
          "
          @creditCheck="
            (mc, amount) => {
              ui_open_credit_check(mc, amount, true);
            }
          "
          @openDirections="openDirections(load)"
          :class="{ 'not-seen': !viewedLoads.includes(load.id) }"
          v-for="load of loads"
          @view="getLoadDetails(load)"
          :key="load.id"
          :load="load"
        />
      </div>
    </div>

    <div style="display: grid; gap: 15px" v-else>
      <LoadItemMobile
        :isAvailable="
          availableLoadsByCurrentPost
            ? availableLoadsByCurrentPost.includes(load.id)
            : true
        "
        @creditCheck="
          (mc, amount) => {
            ui_open_credit_check(mc, amount, true);
          }
        "
        @openDirections="openDirections(load)"
        :class="{ 'not-seen': !viewedLoads.includes(load.id) }"
        v-for="load of loads"
        @view="getLoadDetails(load)"
        :key="load.id"
        :load="load"
      />
    </div>
  </div>
</template>

<script>
import LoadItem from "./components/LoadItem.vue";
import LoadItemMobile from "./components/LoadItemMobile.vue";
import PostItem from "./components/PostItem.vue";
import SavePost from "./components/AddPost";
import TruckstopTokenModal from './components/TruckstopToken'

import Client from "../../../plugins/AppWebSocket/Client";

import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import UI_CreditCheck from "../../../mixins/UI_CreditCheck";

import { mapGetters } from "vuex";

export default {
  mixins: [UI_CreditCheck],
  components: {
    LoadItem,
    LoadItemMobile,
    PostItem,
    DynamicScroller,
    DynamicScrollerItem,
  },
  data() {
    return {
      mobileVersion: false,
      searchTimeout: null,
      posts: [],
      selected: 0,
      postsLoads: {},
      postsLoaded: [],
      loadsCounter: {},
      viewedLoads: [],
      availableLoadsByPost: {},
      table: {
        head: [
          { name: "Age", sortKey: "date", sort: true },
          { name: "Date", sortKey: "pickupDate", sort: true },
          { name: "Equipment" },
          { name: "Type" },
          { name: "DH-O", sortKey: "deadheadMilesOrigin", sort: true },
          { name: "Origin" },
          { name: "Trip", sortKey: "tripMiles", sort: true },
          { name: "Destination" },
          { name: "DH-D", sortKey: "deadheadMilesOrigin", sort: true },
          { name: "Company" },
          { name: "Contact" },
          { name: "Weight", sortKey: "weight", sort: true },
          { name: "Length", sortKey: "length", sort: true },
          { name: "Rate", sortKey: "rate", sort: true },
          { name: "RPM", sortKey: "rpm", sort: true },
        ],
      },
      selectedSort: "date",
      orderAsc: true,
      keepSendingLoadsInterval: null,
    };
  },
  watch: {
    selected() {
      this.trimLoadsLength();
    },
    selectedSort() {
      this.sortLoads();
    },
    orderAsc() {
      this.sortLoads();
    },
  },
  computed: {
    ...mapGetters(["token"]),
    loads() {
      try {
        return this.postsLoads[this.posts[this.selected]._id];
      } catch (e) {
        return [];
      }
    },
    availableLoadsByCurrentPost() {
      try {
        return this.availableLoadsByPost[this.posts[this.selected]._id];
      } catch (e) {
        return null;
      }
    },
  },
  methods: {
    updateTruckstopToken() {
      let modal = this.$ShowModal({
        title: "Update Truckstop Token",
        description: "Enter new Truckstop token",
        component: TruckstopTokenModal,
      });
    },
    getSortedLoads() {
      let sort1 = this.orderAsc ? 1 : -1;
      let sort2 = this.orderAsc ? -1 : 1;

      if (this.selectedSort === "date") {
        sort1 = this.orderAsc ? -1 : 1;
        sort2 = this.orderAsc ? 1 : -1;
      }

      if (this.loads)
        return this.loads.sort((a, b) =>
          a[this.selectedSort] > b[this.selectedSort] ? sort1 : sort2
        );
      else return [];
    },
    sortLoads() {
      try {
        this.postsLoads[this.posts[this.selected]._id] = this.getSortedLoads();
      } catch (e) {}
    },
    selectPost(post, key) {
      this.selected = key;
      this.loadsCounter[post._id] = 0;
    },
    trimLoadsLength() {
      for (const key of Object.keys(this.postsLoads)) {
        let sorted = this.postsLoads[key].sort((a, b) =>
          a.pickupDate > b.pickupDate ? 1 : -1
        );
        let loads = sorted.splice(0, 300);
        this.postsLoads[key] = loads;
      }
      this.sortLoads();
    },
    savePost(post = {}) {
      post = JSON.parse(JSON.stringify(post));
      let modal = this.$ShowModal({
        title: "Add post",
        description: "Enter post details",
        component: SavePost,
        props: {
          post: post,
        },
      });

      modal.onClose(() => {
        this.getUserPosts();
      });
    },
    getUserPosts() {
      this.ajax(
        "GetListOfLoadBoardPosts",
        {
          url: "/dispatch/loadboard/myPosts",
          method: "GET",
        },
        (err, body) => {
          if (!err) {
            this.posts = body;
          }
        }
      );
    },
    getViewedLoads() {
      let loads = localStorage.getItem("viewed-loads");
      if (!loads) {
        loads = [];
      } else {
        try {
          loads = JSON.parse(loads);
        } catch (e) {
          loads = [];
        }
      }

      return loads;
    },
    assignViewedLoads(loads = []) {
      localStorage.setItem("viewed-loads", JSON.stringify(loads));
      this.viewedLoads = loads;
    },
    addLoadToViewed(id) {
      let loads = this.getViewedLoads();
      if (loads.includes(id)) return;
      loads.push(id);
      this.assignViewedLoads(loads);
    },
    getLoadDetails(load) {
      this.addLoadToViewed(load.id);

      let url = `/dispatch/loadboard/load/${load.id}/${load.account}/${load.searchID}`;
      let loaderID = `GetLoadDetails-${load.id}`;
      if (this.isLoading([loaderID])) return;

      this.ajax(
        loaderID,
        {
          url: url,
          method: "GET",
        },
        (err, body) => {
          if (!err) {
            if (body) load.details = body || null;
          }
        }
      );
    },
    selectSort(item) {
      if (!item.sort) return;
      if (this.selectedSort === item.sortKey) this.orderAsc = !this.orderAsc;
      this.selectedSort = item.sortKey;
    },
    async getListOfLoads() {
      let promises = [];

      for (const post of this.posts) {
        promises.push(
          new Promise(async (resolve) => {
            await this.ajax(
              "LoadsPost-" + post._id,
              {
                url: `/dispatch/loadboard/myPosts/loads/${post._id}`,
                method: "GET",
              },
              (err, body) => {
                if (!err) {
                  if (body.status && body.status === 200) {
                    this.availableLoadsByPost[post._id] =
                      this.availableLoadsByPost[post._id] || [];
                    let listOfIds = body.loads.map((load) => load.id);
                    this.availableLoadsByPost[post._id] = listOfIds;

                    if (!this.postsLoaded.includes(post._id)) {
                      this.postsLoaded.push(post._id);
                      this.postsLoads[post._id] = body.loads;
                    } else {
                      for (const load of body.loads) {
                        this.handleLoadUpdate({ post: post._id, load: load });
                      }
                    }
                  }
                }
              }
            );
            resolve();
          })
        );
      }

      await Promise.all(promises);

      this.searchTimeout = setTimeout(this.getListOfLoads, 500);
    },
    findIndex(item, body) {
      if (this.selectedSort === "date") {
        if (!this.orderAsc) {
          return item[this.selectedSort] > body.load[this.selectedSort];
        } else {
          return item[this.selectedSort] < body.load[this.selectedSort];
        }
      }

      if (this.orderAsc) {
        return item[this.selectedSort] > body.load[this.selectedSort];
      } else {
        return item[this.selectedSort] < body.load[this.selectedSort];
      }
    },
    handleLoadUpdate(body) {
      try {
        if (!this.postsLoaded.includes(body.post)) {
          console.log("Post haven't loaded loads first.");
          return;
        }

        this.postsLoads[body.post] = this.postsLoads[body.post] || [];
        this.loadsCounter[body.post] = this.loadsCounter[body.post] || 0;
        let exists = false;
        let i = 0;
        for (const load of this.postsLoads[body.post]) {
          if (load.id === body.load.id) {
            exists = true;

            for (const attr of Object.keys(body.load)) {
              if (attr === "date") continue;
              this.postsLoads[body.post][i][attr] = body.load[attr];
            }

            break;
          }
          i++;
        }
        if (!exists) {
          let that = this;
          let insertIndex = this.postsLoads[body.post].findIndex((item) =>
            this.findIndex(item, body)
          );

          if (insertIndex === -1) {
            insertIndex = this.postsLoads[body.post].length;
          }

          this.postsLoads[body.post].splice(insertIndex, 0, body.load);

          if (body.load.ageInMilliseconds < 60000)
            this.loadsCounter[body.post]++;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async removePost(post, key) {
      let confirmDelete = await this.$ShowConfirm(
        "Do you really wish to remove this post?"
      );
      if (!confirmDelete) return;

      this.posts.splice(key, 1);

      await this.ajax(
        `DeleteLoadBoardPost`,
        {
          url: `/dispatch/loadboard/post/${post._id}`,
          method: "DELETE",
        },
        (err, body) => {
          if (err) {
            this.$ShowAlert(
              body.message || this.TEXT_ERRORS["SOMETHING_WRONG"]
            );
            return;
          }
        }
      );

      this.getUserPosts();
    },
    openDirections(load) {
      let stops = [];

      try {
        let post = this.posts[this.selected];
        if (post.origin.type === "point") {
          stops.push(post.origin.point.address);
        }
      } catch (e) {}

      stops.push(load.origin);
      stops.push(load.destination);

      let url = `https://www.google.com/maps/dir/${stops.join("/")}`;
      window.open(url, "_blank");
    },
    setKeepSendingLoadsInterval() {
      this.$WebSocket.send("/dispatch/loadboard/start", {});
      clearInterval(this.keepSendingLoadsInterval);
      this.keepSendingLoadsInterval = setInterval(() => {
        this.$WebSocket.send("/dispatch/loadboard/start", {});
      }, 30000);
    },
  },
  mounted() {
    this.$WebSocket.on(
      "open",
      "dispatch-loadssearch",
      this.setKeepSendingLoadsInterval
    );
    this.$WebSocket.on(
      "/dispatch/loadboard/load",
      "dispatch-loadssearch",
      this.handleLoadUpdate
    );
    this.setKeepSendingLoadsInterval();
    this.getUserPosts();
    this.viewedLoads = this.getViewedLoads();
    this.getListOfLoads();
  },
  beforeUnmount() {
    clearTimeout(this.searchTimeout);
    clearInterval(this.keepSendingLoadsInterval);
    this.$WebSocket.send("/dispatch/loadboard/stopSearch", {});
  },
};
</script>

<style lang="scss">
.LoadBoard-load {
  display: grid;
  gap: 10px;
  height: 44px;
  align-items: center;
  padding: 0 20px;
  grid-template-columns: minmax(50px, 60px) // age
    minmax(40px, 60px) // date
    minmax(30px, 40px) // equipment
    minmax(30px, 40px) // full or partial
    minmax(40px, 60px) // DH-H
    minmax(100px, 1fr) // origin
    minmax(40px, 60px) // Trip miles
    minmax(100px, 1fr) // destination
    minmax(40px, 60px) // DH-D
    minmax(100px, 1fr) // broker
    minmax(100px, 1fr) // contact
    minmax(70px, 80px) // weight
    minmax(40px, 60px) // length
    minmax(70px, 80px) // rate
    minmax(40px, 60px) // rpm
;
  &.header {
    background: $themeColor1;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    border-bottom: 1px solid $borderColor;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    .header-col {
      display: flex;
      gap: 10px;
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.wrapper {
  height: calc(100vh - 60px);
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
}

.posts {
  display: flex;
  padding: 0 0 0 10px;
  margin-top: 30px;
  gap: 5px;
  position: relative;
  z-index: 2;
  .post {
    &.active {
      border-top-color: $success;
    }
  }
}

.table-holder {
  border-radius: 10px;
  border: 1px solid $borderColor;
  box-shadow: $boxShadow;
  background: $themeColor1;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
}

.load {
  border-bottom: 1px solid $borderColor;
  &.not-seen {
    font-weight: 600;
  }
}
</style>
