<template>
    <div>

        <div style="display: flex; gap: 10px; padding: 20px;">
            <Button :text="item.name" :key="key" theme="white" size="small" @click="selection = JSON.parse(JSON.stringify(item.value))" v-for="(item, key) of quickSelectionList"/>
            <Button :text="'Clear selection'" theme="white" size="small" @click="selection = []" />
        </div>

        <div class="mapHolder">
            <div class="map">
                <LMap ref="LMAP" :maxZoom="5" :minZoom="4" :maxBounds="bounds" :options="{ doubleClickZoom: false }" :inertia="true" :zoomAnimation="false" :zoom="zoom" v-model:center="center" @ready="mapReady">
                    <l-tile-layer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" layer-type="base" name="OpenStreetMap" ></l-tile-layer>

                    <LPolygon
                        :lat-lngs="state.coordinates"
                        :fillColor="selection.includes(state.code) ? 'blue' : '#eee'"
                        :weight="1"
                        :color="'blue'"
                        :fillOpacity="selection.includes(state.code) ? 0.4 : 0.2"
                        :key="key"
                        v-for="(state, key) of statesGeo"
                        @click="areaClicked(state)"
                    />
                </LMap>
            </div>
        </div>
    </div>
</template>

<script>

import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LPopup, LPolygon } from "@vue-leaflet/vue-leaflet";

import { usStates } from '../us-states.js'

    export default {
        props: ['modal', 'states'],
        components: {
            LMap,
            LTileLayer,
            LMarker,
            LPopup,
            LPolygon
        },
        data() {
            return {
                zoom: 3,
                center: [39.781156478647155, -101.40170000039568],
                _map: null,
                bounds: [[49.3457868, -124.7844079], [24.7433195, -66.9513812]],
                statesGeo: usStates,
                selection: this.states || [],
                quickSelectionList: [
                    { name: 'OR, WA', value: ['OR', 'WA'] },
                    { name: 'IL, IN, MI, OH, PA', value: ['IL', 'IN', 'MI', 'OH', 'PA'] }
                ]
            }
        },
        methods: {
            mapReady(ev) {
                this._map = ev;
            },
            areaClicked(state) {
                let code = state.code;
                if(this.selection.includes(code)) {
                    this.selection.splice(this.selection.indexOf(code), 1);
                } else {
                    this.selection.push(code);
                }
            },
            onMouseOver(e) {
                e.target.setStyle({
                    fillColor: 'red',
                });
            },
            onMouseOut(e) {
                e.target.setStyle({
                    fillColor: 'blue',
                });
            },
        },
        watch: {
            selection: {
                deep: true,
                handler() {
                    this.modal.setProp('states', this.selection)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.mapHolder {
    width: 100%;
    padding-top: 75%;
    position: relative;
    .map {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.state {
    &:hover {
        fill: black
    }
}
</style>