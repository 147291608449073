<template>
    <div class="post" @click="$emit('select'); resetAlert()" :class="[ { 'menu-shown' : menuShown } ]">
        <div class="origin text-overflow">
            <span v-if="post.origin.type === 'point'">{{ post.origin.point.city }}, {{ post.origin.point.state }}</span>
            <span v-if="post.origin.type === 'areas'">{{ post.origin.areas.join(', ') }}</span>
        </div>
        <div class="destination text-overflow">
            <span v-if="post.destination.type === 'point'">{{ post.destination.point.city }}, {{ post.destination.point.state }}</span>
            <span v-if="post.destination.type === 'areas'">{{ post.destination.areas.join(', ') }}</span>
        </div>

        <DropDownMenuButton class="menu" @click.stop @show="menuShown = true" @hide="menuShown = false" :options="menu" @remove="$emit('remove')" @edit="$emit('edit')" />

        <div class="alert-btn">
            <button @click.stop="alertOn = !alertOn" :class="[{ 'active' : counter > 0 }]">
                <span v-if="alertOn"><i class="fa-solid fa-bell"></i></span>
                <span v-else><i class="fa-solid fa-bell-slash"></i></span>
            </button>
        </div>

    </div>
</template>

<script>
import moment from 'moment';

    export default {
        props: ['post', 'counter'],
        data() {
            return {
                alertOn: false,
                lastAlert: moment().subtract(1, 'hour'),
                menuShown: false,
                menu: [
                    { name: 'Edit post', emit: 'edit' },
                    { name: 'Remove post', emit: 'remove', type: 'remove' },
                ]
            }
        },
        methods: {
            resetAlert() {
                this.lastAlert = moment().subtract(1, 'hour');
            }
        },
        watch: {
            counter(to, from) {
                let SECONDS = 5;

                if(!this.alertOn) return;
                let now = moment();
                let diff = now.diff(this.lastAlert, 'seconds');
                if(diff < SECONDS) return;
                if(!from && to >= SECONDS || to > from) {
                    this.lastAlert = now.clone();
                    let audio = new Audio('/sound/alert.wav');
                    audio.play();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.post {
    padding: 10px 20px 10px 60px;
    border-top: 3px solid $borderColor;
    border-left: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    border-radius: 7px 7px 0 0;
    background: $themeColor1;
    box-shadow: $boxShadow;
    width: 294px;
    cursor: pointer;
    position: relative;
    &.menu-shown {
        z-index: 1;
    }
}

.alert-btn {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    button {
        width: 34px;
        height: 34px;
        border: 0;
        background: transparent;
        font-size: 14px;
        cursor: pointer;
        padding: 0;
        color: $text;
        &.active {
            color: $success;
        }
    }
}

.menu {
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>