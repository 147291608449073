<template>
    <div class="post-wrapper">
        
        
        <div class="grid gap-15">

            <div class="grid cols-2 gap-15">
                <div class="input">
                    <div class="_header">
                        <label>Origin</label>
                        <span class="_error">{{ errors.origin }}</span>
                    </div>

                    <div class="grid gap-5">
                        <div v-if="item.origin" class="text-overflow">
                            <div v-if="item.origin.type === 'point' && item.origin.point.address">
                                <div>{{ item.origin.point.address }}</div>
                                <div><strong>{{ item.originDeadHead }}</strong> miles</div>
                            </div>
                            <div v-else-if="item.origin.type === 'areas' && item.origin.areas">{{ item.origin.areas.join(', ') }}</div>
                            <div v-else>No origin selected</div>
                        </div>
                        <div v-else>No origin selected</div>
                        <a href="" @click.prevent="selectOriginDestination('origin')">Select origin</a>
                    </div>
                </div>

                <div class="input">
                    <div class="_header">
                        <label>Destination</label>
                        <span class="_error">{{ errors.destination }}</span>
                    </div>

                    <div class="grid gap-5">
                        <div v-if="item.destination" class="text-overflow">
                            <div v-if="item.destination.type === 'point' && item.destination.point.address">
                                <div>{{ item.destination.point.address }}</div>
                                <div><strong>{{ item.destinationDeadHead }}</strong> miles</div>
                            </div>
                            <div v-else-if="item.destination.type === 'areas' && item.destination.areas">{{ item.destination.areas.join(', ') }}</div>
                            <div v-else>No destination selected</div>
                        </div>
                        <div v-else>No destination selected</div>
                        <a href="" @click.prevent="selectOriginDestination('destination')">Select destination</a>
                    </div>
                </div>
            </div>

            <InputDropDownDatepicker v-model="item.date" :error="errors.date" placeholder="Select date" :range="true" name="Date"/>
            <InputDropDownCheckbox name="Equipment" :error="errors.equipment" placeholder="Select equipment" selectAttribute="_id" showAttribute="name" v-model="item.equipment" :options="equipment"/>

            <div class="grid cols-2 gap-15">
                <Input v-model="item.length" name="Length" placeholder="Length ft" :error="errors.length"/>
                <Input v-model="item.weight" name="Weight" placeholder="Weight lbs" :error="errors.weight"/>
            </div>


        </div>


    </div>
</template>

<script>
import OriginDestinationPicker from './OriginDestinationPicker.vue'
import OriginDestinationPickerV2 from './OriginDestinationPicker.v2.vue'
    export default {
        props: ['post', 'modal'],
        components: {
            OriginDestinationPickerV2
        },
        data() {
            return {
                item: this.post || {},
                errors: {},
                equipment: []
            }
        },
        methods: {
            async getEquipment() {
                this.modal.processing(true);

                await this.ajax('GetListOfLoadBoardEquipment', {
                    url: '/dispatch/loadboard/equipment',
                    method: 'GET'
                }, (err, body) => {
                    if(!err) {
                        this.equipment = body;
                    }
                });

                this.modal.processing(false);
            },
            selectOriginDestination(attribute) {
                let item = this.item[attribute] || {};
                item = JSON.parse(JSON.stringify(item));
                let modal = this.$ShowModal({
                    title: 'Select location',
                    component: OriginDestinationPicker,
                    props: {
                        item,
                        deadhead: attribute === 'origin' ? (this.item.originDeadHead ? this.item.originDeadHead : 100) : attribute === 'destination' ? (this.item.destinationDeadHead ? this.item.destinationDeadHead : 100) : 100
                    }
                });

                modal.onConfirm((props) => {
                    if(props.item) {
                        if(props.item.type === 'point') {
                            let deadhead = props.deadhead;
                            if(attribute === 'origin') {
                                this.item.originDeadHead = deadhead;
                            } else if(attribute === 'destination') {
                                this.item.destinationDeadHead = deadhead
                            }
                        }
                        this.item[attribute] = props.item;
                    }
                    modal.close();
                });
            },
            async handlePostSave() {
                this.modal.processing(true);

                this.errors = {}
                await this.ajax('HandleLoadBoardPostSave', {
                    url: '/dispatch/loadboard/post',
                    method: 'post',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG'])
                        return;
                    }
                    this.modal.close();
                });

                this.modal.processing(false);
            }
        },
        mounted() {
            this.getEquipment();
            this.modal.onConfirm(this.handlePostSave);
        }
    }
</script>

<style lang="scss" scoped>
.post-wrapper {
    padding: 20px;
}

.equipment-item {
    display: flex;
    gap: 15px;
    align-items: center;
}
</style>