<template>
    <div>
        <div class="LoadBoard-load-mobile grid gap-15" :class="{ 'not-available' : !isAvailable }" @click="viewLoad">

            <div style="display: grid; grid-template-columns: auto auto; justify-content: space-between;">
                <div>
                    <span>{{ age }}</span>&nbsp;
                    <span>{{ load.equipmentClass }}</span>&nbsp;
                    <span>{{ load.isPartial ? 'PTL' : 'TL' }}</span>
                </div>
                <!-- <div class="broker text-overflow">{{ load.company }}</div> -->
                <div class="tripMiles text-overflow">
                    <a href="" @click.stop.prevent="$emit('openDirections')">{{ load.tripMiles }}</a>    
                </div>
            </div>



            <div class="stops-list">
                <div class="stop-item pick">
                    <div class="stop-type">{{ load.origin }}&nbsp;<span class="deadhead">{{ load.deadheadMilesOrigin }}</span></div>
                    <div class="stop-date">{{ date }}</div>
                </div>
                <div class="stop-item">
                    <div class="stop-type">{{ load.destination }}&nbsp;<span class="deadhead">{{ load.deadheadMilesDestination }}</span></div>
                </div>
            </div>


            <div style="display: grid; grid-template-columns: auto auto; justify-content: space-between;">
                <div class="text-overflow">
                    <span v-if="load.weight"><strong>{{ CONVERT_PRICE(load.weight) }}</strong> lbs</span>&nbsp;
                    <span v-if="load.length"><strong>{{ CONVERT_PRICE(load.length) }}</strong> ft</span>&nbsp;
                </div>
                <div class="rate text-overflow">
                    <span v-if="load.rate">${{ CONVERT_PRICE(load.rate.toFixed(0)) }}</span>
                    <span v-else>-</span>
                </div>
            </div>

            <div>
                <div v-if="load.account === 'truckersedge'">
                    <div v-if="load.contact.type === 'Phone'">
                        <a :href="`tel:${load.contact.phone}`" @click.stop>{{ load.contact.phone }}</a>
                        <span v-if="load.contact.phoneExt">&nbsp;<a href="" @click.stop.prevent>x{{ load.contact.phoneExt }}</a></span>
                    </div>
                    <div v-else-if="load.contact.type === 'Email'">
                        <a href="" @click.stop.prevent="COPY_TEXT(load.contact.email); $Toast.showAlert('Email have been copied.')">{{ load.contact.email }}</a>
                    </div>
                </div>
                <div v-else>
                    <a :href="`tel:${load.contact}`" @click.stop>{{ load.contact }}</a>
                </div>
            </div>

            <!-- <div class="text-overflow">{{ age }}</div>
            <div class="text-overflow">{{ date }}</div>
            <div class="text-overflow">{{ load.equipmentClass }}</div>
            <div class="text-overflow">{{ load.isPartial ? 'PTL' : 'TL' }}</div>
            <div class="text-overflow">{{ load.deadheadMilesOrigin }}</div>
            <div class="text-overflow">{{ load.origin }}</div>
            <div class="text-overflow"><a href="" @click.stop.prevent="$emit('openDirections')">{{ load.tripMiles }}</a></div>
            <div class="text-overflow">{{ load.destination }}</div>
            <div class="text-overflow">{{ load.deadheadMilesDestination }}</div>
            <div class="text-overflow">{{ load.company }}</div>
            <div class="text-overflow">
                <div v-if="load.account === 'truckersedge'">
                    <div v-if="load.contact.type === 'Phone'">
                        <a :href="`tel:${load.contact.phone}`" @click.stop>{{ load.contact.phone }}</a>
                        <span v-if="load.contact.phoneExt">&nbsp;<a href="" @click.stop.prevent>x{{ load.contact.phoneExt }}</a></span>
                    </div>
                    <div v-else-if="load.contact.type === 'Email'">
                        <a href="" @click.stop.prevent="COPY_TEXT(load.contact.email); $Toast.showAlert('Email have been copied.')">{{ load.contact.email }}</a>
                    </div>
                </div>
                <div v-else>
                    <a :href="`tel:${load.contact}`" @click.stop>{{ load.contact }}</a>
                </div>
            </div>
            <div class="text-overflow"><span v-if="load.weight"><strong>{{ CONVERT_PRICE(load.weight) }}</strong> lbs</span></div>
            <div class="text-overflow"><span v-if="load.length"><strong>{{ load.length }}</strong> ft</span></div>
            <div class="text-overflow"><strong v-if="load.rate">${{ CONVERT_PRICE(load.rate.toFixed(0)) }}</strong></div>
            <div class="text-overflow"><strong v-if="load.rpm">${{ CONVERT_PRICE(load.rpm.toFixed(2)) }}</strong></div> -->
        </div>

        <div class="loadDetailsHolder" v-if="show">
            <div v-if="isLoading([loaderID]) && !load.details">loading...</div>
            <div v-else-if="load.details" class="loadDetailsContentHolder">
                <div class="loadDetailsContent">

                    
                    
                    <div class="detailsGrid">

                        <div class="item" v-if="load.company">
                            <div class="name">Broker</div>
                            <div class="value">{{ load.company }}</div>
                        </div>

                        <div class="item">
                            <div class="name">Broker MC#</div>
                            <div class="value" v-if="load.details.mc"><a href="" @click.prevent="$emit('creditCheck', load.details.mc, load.rate)">{{ load.details.mc }}</a> <span style="margin-left: 10px;" v-if="load.isFactorable"><i class="fas fa-check"></i></span></div>
                            <div class="value" v-else>NOT FOUND</div>
                        </div>

                        <div class="item" v-if="load.details.commodity">
                            <div class="name">Commodity</div>
                            <div class="value">{{ load.details.commodity }}</div>
                        </div>


                        <div class="item" v-if="load.details.notes">
                            <div class="name">Notes</div>
                            <div class="value">{{ load.details.notes }}</div>
                        </div>

                        
                        <div class="item" :key="key" v-for="(comment, key) of load.comments">
                            <div class="name">Comments {{ key + 1 }}</div>
                            <div class="value">{{ comment }}</div>
                        </div>

                        

                        <div class="item" v-if="load.referenceId">
                            <div class="name">Reference</div>
                            <div class="value">{{ load.referenceId  }}</div>
                        </div>
                    </div>

                    <div class="detailsGrid">
                        <div class="item">
                            <div class="name">Dimensions</div>
                            <div class="value">{{ load.details.length }} x {{ load.details.width }} x {{ load.details.height }}</div>
                        </div>

                        <div class="item">
                            <div class="name">Pallets</div>
                            <div class="value">{{ load.details.palletCount }}</div>
                        </div>

                        <div class="item">
                            <div class="name">Pieces</div>
                            <div class="value">{{ load.details.pieceCount }}</div>
                        </div>

                        <div class="item">
                            <div class="name">Stops</div>
                            <div class="value">{{ load.details.stops }}</div>
                        </div>
                    </div>


                </div>

                <div class="actionButtons">
                    <Button text="Copy Load" theme="white" size="small" @click="copyLoad"/>
                </div>

            </div>
            <div v-else>Cannot load details. Try again!</div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { findPhoneNumbersInText } from 'libphonenumber-js'
import extractEmail from 'extract-email-address';

    export default {
        props: ['load', 'isAvailable'],
        data() {
            return {
                show: false
            }
        },
        computed: {
            loaderID() {
                return `GetLoadDetails-${this.load.id}`;
            },
            age() {
                let date = moment.utc(this.load.date);
                let now = moment.utc();
                let minutes = moment.duration(now.diff(date)).asMinutes();
                let age = Number((minutes).toFixed(0));
                if(age >= 60) return '1h+'
                return age + 'm';
            },
            date() {
                return moment.utc(this.load.pickupDate).format("D MMM")
            }
        },
        methods: {
            copyLoad() {
                let text = `${this.load.origin} - ${this.load.destination}`;

                if(this.load.weight) {
                    text += `\n${this.CONVERT_PRICE(this.load.weight)} lbs`
                }

                if(this.load.rate) {
                    text += `\n$${this.CONVERT_PRICE(this.load.rate)}`
                }

                if(this.load.rpm) {
                    text += `\nRPM: $${this.CONVERT_PRICE(this.load.rpm.toFixed(2))}`
                }

                if(this.load.tripMiles) {
                    let tripMiles = this.load.tripMiles;
                    let DH = this.load.deadheadMilesOrigin || '-';
                    if(this.load.deadheadMilesOrigin) tripMiles += this.load.deadheadMilesOrigin;
                    text += `\n\n${tripMiles} miles (DH: ${DH})`
                }

                this.COPY_TEXT(text);
                this.$Toast.showAlert('Load details have been copied.');
            },
            viewLoad() {
                this.show = !this.show;
                if(this.show) {
                    this.$emit('view');
                }
            },
            callOrCopyContact() {
                let phoneNumbers = findPhoneNumbersInText(this.load.contact, 'US');
                if(phoneNumbers.length > 0) {
                    window.open("tel:" + phoneNumbers[0].number.number, '_self');
                    this.COPY_TEXT(this.load.contact);
                } else {
                    this.COPY_TEXT(this.load.contact);
                }
            },
            convertedTextEl(value) {
                let formatted = value;
                let phoneNumbers = findPhoneNumbersInText(value, 'US');
                
                for(const item of phoneNumbers) {
                    let itemVal = value.substring(item.startsAt, item.endsAt);
                    formatted = formatted.replace(itemVal, `<a href="" @click.prevent="callPhone('${itemVal}')">${itemVal}</a>`)
                }

                let emails = extractEmail(value);
                for(const item of emails) {
                    var pattern = new RegExp(item.email, 'gi');
                    formatted = formatted.replace(pattern, `<a href="" @click.prevent="COPY_TEXT('${item.email}')">${item.email}</a>`)
                }

                return {
                    methods: {
                        callPhone(phone) {
                            this.COPY_TEXT(phone);
                            window.open("tel:" + phone, '_self');
                        }
                    },
                    template: `<div>${formatted}</div>`
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

.rate {
    color: $success;
    font-size: 16px;
    font-weight: 500;
}

.deadhead {
    font-size: 12px;
    color: $textLight;
    font-weight: 500;
}

.stops-list {
    .stop-item {
        color: $textShade;
        padding: 2px 0 2px 16px;
        position: relative;
        display: grid;
        row-gap: 2px;
        .stop-date {
            color: rgba(0,0,0,0.7);
            margin-top: 3px;
        }
        &::before {
            content: '';
            position: absolute;
            top: 2px;
            left: 0;
            width: 5px;
            height: 6px;
            background: $themeColor2;
            border: 1px solid $themeColor2;
            box-sizing: border-box;
            transform: translate(0px, 5px);
        }
        &::after {
            content: '';
            position: absolute;
            top: 4px;
            left: 0;
            bottom: 6px;
            width: 1px;
            background: $themeColor2;
            transform: translate(2px, 11px);
        }
        &:last-child {
            &::after {
                content: none;
            }
        }
        &.pick {
            &::before {
                background: transparent;
            }
        }
        .stop-type {
            display: flex;
            align-items: center;
            font-size: 15px;
            height: 16px;
        }
    }
}

.LoadBoard-load-mobile {
    cursor: pointer;
    position: relative;
    border: 1px solid $borderColor;
    background: $themeColor1;
    padding: 15px;
    width: 100%;
    border-radius: 3px;
    &:hover {
        background: rgba(0,0,0,0.05);
    }
    &.not-available {
        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255,255,255,0.5);
            pointer-events: none;
        }
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 20px;
            right: 20px;
            height: 1px;
            background: #ccc;
            transform: translateY(-50%);
            pointer-events: none;
        }
        &:hover {
            background: inherit;
        }
    }
}

.loadDetailsHolder {
    padding: 10px;
    background: $themeColor1;
    border-left: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    border-radius: 0 0 3px 3px;
}


.loadDetailsContent {
    display: grid;
    gap: 10px;
    align-items: start;
    align-content: start;
}

.loadDetailsContentHolder {
    display: grid;
    row-gap: 10px;
}

.detailsGrid {
    display: grid;
    row-gap: 10px;
    align-items: start;
    align-content: start;
}


.item {
    display: grid;
    gap: 5px;
    align-items: start;
    align-content: start;
    .name {
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 20px;
    }
    .value {
        line-height: 20px;
        font-size: 13px;
    }
}

.actionButtons {
    display: flex;
    gap: 15px;
}
</style>