<template>
    <div>
        <div class="LoadBoard-load" :class="{ 'not-available' : !isAvailable }" @click="viewLoad">
            <div class="text-overflow">{{ age }}</div>
            <div class="text-overflow">{{ date }}</div>
            <div class="text-overflow">{{ load.equipmentClass }}</div>
            <div class="text-overflow">{{ load.isPartial ? 'PTL' : 'TL' }}</div>
            <div class="text-overflow">{{ load.deadheadMilesOrigin }}</div>
            <div class="text-overflow">{{ load.origin }}</div>
            <div class="text-overflow"><a href="" @click.stop.prevent="$emit('openDirections')">{{ load.tripMiles }}</a></div>
            <div class="text-overflow">{{ load.destination }}</div>
            <div class="text-overflow">{{ load.deadheadMilesDestination }}</div>
            <div class="text-overflow">{{ load.company }}</div>
            <div class="text-overflow">
                <div v-if="load.account === 'truckersedge'">
                    <div v-if="load.contact.type === 'Phone'">
                        <a :href="`tel:${load.contact.phone}`" @click.stop>{{ load.contact.phone }}</a>
                        <span v-if="load.contact.phoneExt">&nbsp;<a href="" @click.stop.prevent>x{{ load.contact.phoneExt }}</a></span>
                    </div>
                    <div v-else-if="load.contact.type === 'Email'">
                        <a :href="`mailto:${load.contact.email}`" @click.stop.prevent="COPY_TEXT(load.contact.email); $Toast.showAlert('Email have been copied.')">{{ load.contact.email }}</a>
                    </div>
                </div>
                <div v-else>
                    <a :href="`tel:${load.contact}`" @click.stop>{{ load.contact }}</a>
                </div>
            </div>
            <div class="text-overflow"><span v-if="load.weight"><strong>{{ CONVERT_PRICE(load.weight) }}</strong> lbs</span></div>
            <div class="text-overflow"><span v-if="load.length"><strong>{{ load.length }}</strong> ft</span></div>
            <div class="text-overflow"><strong v-if="load.rate">${{ CONVERT_PRICE(load.rate.toFixed(0)) }}</strong></div>
            <div class="text-overflow"><strong v-if="load.rpm">${{ CONVERT_PRICE(load.rpm.toFixed(2)) }}</strong></div>
        </div>

        <div class="loadDetailsHolder" v-if="show">
            <div v-if="isLoading([loaderID]) && !load.details">loading...</div>
            <div v-else-if="load.details" class="loadDetailsContentHolder">
                <div class="loadDetailsContent">
                    
                    <div class="detailsGrid">
                        <div class="item" v-if="load.details.commodity">
                            <div class="name">Commodity</div>
                            <div class="value">{{ load.details.commodity }}</div>
                        </div>


                        <div class="item" v-if="load.details.notes">
                            <div class="name">Notes</div>
                            <div class="value">{{ load.details.notes }}</div>
                        </div>

                        
                        <div class="item" :key="key" v-for="(comment, key) of load.comments">
                            <div class="name">Comments {{ key + 1 }}</div>
                            <div class="value">{{ comment }}</div>
                        </div>

                        <div class="item">
                            <div class="name">Broker</div>
                            <div class="value" v-if="load.details.mc"><a href="" @click.prevent="$emit('creditCheck', load.details.mc, load.rate)">{{ load.details.mc }}</a> <span style="margin-left: 10px;" v-if="load.isFactorable"><i class="fas fa-check"></i></span></div>
                            <div class="value" v-else>NOT FOUND</div>
                        </div>

                        <div class="item" v-if="load.referenceId">
                            <div class="name">Reference</div>
                            <div class="value">{{ load.referenceId  }}</div>
                        </div>
                    </div>

                    <div class="detailsGrid">
                        <div class="item">
                            <div class="name">Dimensions</div>
                            <div class="value">{{ load.details.length }} x {{ load.details.width }} x {{ load.details.height }}</div>
                        </div>

                        <div class="item">
                            <div class="name">Pallets</div>
                            <div class="value">{{ load.details.palletCount }}</div>
                        </div>

                        <div class="item">
                            <div class="name">Pieces</div>
                            <div class="value">{{ load.details.pieceCount }}</div>
                        </div>

                        <div class="item">
                            <div class="name">Stops</div>
                            <div class="value">{{ load.details.stops }}</div>
                        </div>
                    </div>


                </div>

                <div class="actionButtons">
                    <Button text="Copy Load" theme="white" size="small" @click="copyLoad"/>
                    <a :href="`https://www.navispherecarrier.com/find-loads/single?loadNumber=${load.id}`" target="_blank" v-if="load.account === 'chrobinson'"><Button text="CH Robinson Website" size="small"/></a>
                    <!-- <Button text="Share load" theme="white" size="small"/> -->
                </div>

            </div>
            <div v-else>Cannot load details. Try again!</div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { findPhoneNumbersInText } from 'libphonenumber-js'
import extractEmail from 'extract-email-address';

    export default {
        props: ['load', 'isAvailable'],
        data() {
            return {
                show: false
            }
        },
        computed: {
            loaderID() {
                return `GetLoadDetails-${this.load.id}`;
            },
            age() {
                let date = moment.utc(this.load.date);
                let now = moment.utc();
                let minutes = moment.duration(now.diff(date)).asMinutes();
                let age = Number((minutes).toFixed(0));
                if(age >= 60) return '1h+'
                return age + 'm';
            },
            date() {
                return moment.utc(this.load.pickupDate).format("D MMM")
            }
        },
        methods: {
            copyLoad() {
                let text = `${this.load.origin} - ${this.load.destination}`;

                if(this.load.weight) {
                    text += `\n${this.CONVERT_PRICE(this.load.weight)} lbs`
                }

                if(this.load.rate) {
                    text += `\n$${this.CONVERT_PRICE(this.load.rate)}`
                }

                if(this.load.rpm) {
                    text += `\nRPM: $${this.CONVERT_PRICE(this.load.rpm.toFixed(2))}`
                }

                if(this.load.tripMiles) {
                    let tripMiles = this.load.tripMiles;
                    let DH = this.load.deadheadMilesOrigin || '-';
                    if(this.load.deadheadMilesOrigin) tripMiles += this.load.deadheadMilesOrigin;
                    text += `\n\n${tripMiles} miles (DH: ${DH})`
                }

                this.COPY_TEXT(text);
                this.$Toast.showAlert('Load details have been copied.');
            },
            viewLoad() {
                this.show = !this.show;
                if(this.show) {
                    this.$emit('view');
                }
            },
            callOrCopyContact() {
                let phoneNumbers = findPhoneNumbersInText(this.load.contact, 'US');
                if(phoneNumbers.length > 0) {
                    window.open("tel:" + phoneNumbers[0].number.number, '_self');
                    this.COPY_TEXT(this.load.contact);
                } else {
                    this.COPY_TEXT(this.load.contact);
                }
            },
            convertedTextEl(value) {
                let formatted = value;
                let phoneNumbers = findPhoneNumbersInText(value, 'US');
                
                for(const item of phoneNumbers) {
                    let itemVal = value.substring(item.startsAt, item.endsAt);
                    formatted = formatted.replace(itemVal, `<a href="" @click.prevent="callPhone('${itemVal}')">${itemVal}</a>`)
                }

                let emails = extractEmail(value);
                for(const item of emails) {
                    var pattern = new RegExp(item.email, 'gi');
                    formatted = formatted.replace(pattern, `<a href="" @click.prevent="COPY_TEXT('${item.email}')">${item.email}</a>`)
                }

                return {
                    methods: {
                        callPhone(phone) {
                            this.COPY_TEXT(phone);
                            window.open("tel:" + phone, '_self');
                        }
                    },
                    template: `<div>${formatted}</div>`
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.LoadBoard-load {
    cursor: pointer;
    position: relative;
    &:hover {
        background: rgba(0,0,0,0.05);
    }
    &.not-available {
        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255,255,255,0.5);
            pointer-events: none;
        }
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 20px;
            right: 20px;
            height: 1px;
            background: #ccc;
            transform: translateY(-50%);
            pointer-events: none;
        }
        &:hover {
            background: inherit;
        }
    }
}

.loadDetailsHolder {
    padding: 20px;
    border-top: 1px solid $borderColor;
}


.loadDetailsContent {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 50px;
    align-items: start;
    align-content: start;
}

.loadDetailsContentHolder {
    display: grid;
    row-gap: 10px;
}

.detailsGrid {
    display: grid;
    row-gap: 10px;
    align-items: start;
    align-content: start;
}


.item {
    display: grid;
    grid-template-columns: 150px minmax(0, 1fr);
    column-gap: 20px;
    align-items: start;
    align-content: start;
    .name {
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 20px;
    }
    .value {
        line-height: 20px;
        font-size: 13px;
    }
}

.actionButtons {
    display: flex;
    gap: 15px;
}
</style>