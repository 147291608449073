import CreditCheckModal from '../views/Dispatch/LoadSearch/components/CreditCheck';


export default {
    methods: {
        ui_open_credit_check(mc, amount, auto = false) {
            
            let modal = this.$ShowModal({
                title: `Credit Check`,
                component: CreditCheckModal,
                width: '449px',
                confirm: 'Check',
                props: {
                    userMc: mc,
                    userLoadValue: amount,
                    auto
                }
            });

            

        }
    },
}