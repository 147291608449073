<template>
    <div style="padding: 20px;">
        <Input v-model="token" name="Truckstop Token" placeholder="Truckstop Token"/>
    </div>
</template>

<script>
    export default {
        props: ['modal'],
        data() {
            return {
                token: ''
            }
        },
        methods: {
            updateToken() {
                this.ajax('updateTruckStopToken', {
                    url: `/dispatch/loadboard/token`,
                    method: 'POST',
                    data: {
                        token: this.token
                    }
                }, (err, body) => {
                    this.modal.close();
                });
            }
        },
        mounted() {
            this.modal.onConfirm(() => {
                this.updateToken();
            })
        }
    }
</script>

<style lang="scss" scoped>

</style>