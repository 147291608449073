<template>
    <div>
        <CitySearch v-model="item.point" name="Origin"/>
    </div>
</template>

<script>
import CitySearch from '../../../../components/Dispatch/Loads/CitySearch.vue';
import StatesPickerVue from './StatesPicker.vue';

    export default {
        components: {
            CitySearch,
            StatesPickerVue
        },
        props: ['modelValue'],
        data() {
            return {
                item: this.modelValue || {
                    type: 'point',
                    point: {}
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>